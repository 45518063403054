import { useAsyncWrapper } from "@/composables/useAsyncWrapper";

export default {
    ServiceViewModule: {
      ...useAsyncWrapper('ServiceViewModule')
    },
  ProductTagViewModule: {
      ...useAsyncWrapper('ProductTagViewModule')
  },
  CartPromoViewModule: {
    ...useAsyncWrapper('CartPromoViewModule')
  }
}
